import { lazy } from "react";

import { Navigate } from "react-router-dom";
import {
  PrivateRoute,
  UnAuthRoute,
  UserRoute,
  HmoRoute,
  ITLRoute,
  ITLUnAuthRoute,
  HospitalRoute,
  UserUnAuthRoute,
  HmoUnAuthRoute,
  HospitalUnAuthRoute,
} from "./RouteMiddleware";
import {
  HomeTwoTone,
  LockOpenTwoTone,
  ErrorTwoTone,
  Business,
  AccountCircleTwoTone,
  BallotTwoTone,
} from "@material-ui/icons";
import InvoiceList from "../views/Hmo/InvoiceList";
// Policy
/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const BlankLayout = lazy(() => import("../layouts/BlankLayout/BlankLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Home = lazy(() => import("../views/Home"));
const LandingPage = lazy(() => import("../views/HomeScreen"));
const ContactUs = lazy(() => import("../views/ContactUs.js"));
const Verification = lazy(() => import("../views/VerifyEmail"));
const UserManageBeneficiaries = lazy(() =>
  import("../views/UserManageBeneficiaries")
);
const AddBeneficiaries = lazy(() =>
  import("../views/AddBeneficiary")
);
const TreatmentLog = lazy(() => import("../views/Hmo/TreatmentLog"));

const BenefitLog = lazy(() => import("../views/Hmo/TreatmentLog.BenefitLog"));
const Error = lazy(() => import("../views/error/error"));
const Login = lazy(() => import("../views/Login"));
const AdminLogin = lazy(() => import("../views/Admin/Login"));
const AdminHmoList = lazy(() => import("../views/Admin/HmoList"));
const AdminUserList = lazy(() => import("../views/Admin/UserList"));
const AdminInvoiceList = lazy(() => import("../views/Admin/Invoices"));
const HmoList = lazy(() => import("../views/HmoList"));
const Register = lazy(() => import("../views/Register"));
const HmoLogin = lazy(() => import("../views/Hmo/HmoLogin"));
const HmoRegistration = lazy(() => import("../views/Hmo/HmoRegistration"));
const HmoHospitals = lazy(() => import("../views/Hmo/HmoHospitalsList"));
const Policy = lazy(() => import("../views/Hmo/UploadPolicy"));
const SubReciept = lazy(() => import("../views/Hmo/Reciept"));
const ProfilePage = lazy(() => import("../views/Profile"));
const HmoProfilePage = lazy(() => import("../views/HmoProfile"));
const ForgotPassword = lazy(() => import("../views/ForgotPassword"));
const ChangeForgotPassword = lazy(() =>
  import("../views/ChangeForgotPassword")
);
const Payment = lazy(() => import("../views/Payment"));
const HmoPackages = lazy(() => import("../views/Hmo/Packages"));
const HmoUploadHospitals = lazy(() => import("../views/Hmo/HmoHospitalUpload"));
const Subcribers = lazy(() => import("../views/Hmo/SubscriptionManagement"));
const PaymentConfirm = lazy(() => import("../views/PaymentConfirm"));
const Subscription = lazy(() => import("../views/Subscriptions"));
const HmoSubscriptions = lazy(() => import("../views/Hmo/HmoSubscriptions"));
const IdCard = lazy(() => import("../views/IdCard"));
const TreatmentLogAlone = lazy(() =>
  import("../views/Hospital/TreatmentLogAlone")
);
const NewLog = lazy(() => import("../views/Hospital/NewLog"));
const Previous = lazy(() => import("../views/Previous"));
const Licence = lazy(() => import("../views/Licence"));
const Term = lazy(() => import("../views/TermsOfUse"));
const OnePrevious = lazy(() => import("../views/ViewOneTreatment"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));
const HmoChangePassword = lazy(() => import("../views/Hmo/HmoChangePassword"));
const HospitalTreatments = lazy(() =>
  import("../views/Hmo/ViewHospitalTreatment")
);
const OneHospitalTreatments = lazy(() =>
  import("../views/Hmo/ViewOneTreatment")
);
const HmoInvoices = lazy(() => import("../views/Hmo/Invoices"));
const HospHospitalTreatments = lazy(() =>
  import("../views/Hospital/ViewHospitalTreatment")
);
const OneHospHospitalTreatments = lazy(() =>
  import("../views/Hospital/ViewOneTreatment")
);
const HospitalInvoices = lazy(() => import("../views/Hospital/AllInvoices"));
const HmoPackageBenfits = lazy(() => import("../views/Hmo/HmoBenefits"));
const ViewPackages = lazy(() => import("../views/Hmo/ViewPackages"));
const Reciept = lazy(() => import("../views/Reciept"));
const HMOServicePayment = lazy(() => import("../views/Hmo/HMOServicePayment"));
const SubcriberPaymentLog = lazy(() => import("../views/SubcriberPaymentLog"));
const FullSubcriptionDetails = lazy(() =>
  import("../views/FullSubcriptionDetails")
);
const UserTreatmentLog = lazy(() => import("../views/TreatmentLog.User"));
const UserBenefitLog = lazy(() =>
  import("../views/TreatmentLog.User.BenefitLog")
);
const HospitalLoginAccess = lazy(() =>
  import("../views/Hospital/HospitalAccess")
);
const ConfirmID = lazy(() => import("../views/Hospital/ConfirmUserID"));
const HmoForgotPassword = lazy(() => import("../views/Hmo/ForgotPassword"));
const HmoChangeForgotPassword = lazy(() =>
  import("../views/Hmo/ChangeForgotPassword")
);
const SearchHospitals = lazy(() => import("../views/SearchHospital"));
const GenerateInvoice = lazy(() => import("../views/Hospital/GenerateInvoice"));
const AdminReconWithHMO = lazy(() => import("../views/Admin/Reconcilliation"));
const AdminReceipt = lazy(() => import("../views/Admin/Reciept"));
const HmoGenerateInvoice = lazy(() => import("../views/Hmo/HmoRaiseInvoice"));

// SearchHospitals
// const HmoForgotPassword

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "dashboard",
        icon: HomeTwoTone,
        exact: true,
        element: <PrivateRoute component={Home} />,
      },
      {
        path: "profile",
        icon: AccountCircleTwoTone,
        element: <UserRoute component={ProfilePage} />,
      },
      {
        path: "hmo/profile",
        icon: AccountCircleTwoTone,
        element: <HmoRoute component={HmoProfilePage} />,
      },
      {
        path: "chart",
        icon: ErrorTwoTone,
        element: <PrivateRoute component={Home} />,
      },
      {
        path: "payments",
        exact: true,
        element: <PrivateRoute component={Payment} />,
      },
      {
        path: "hmo-packages",
        exact: true,
        element: <HmoRoute component={HmoPackages} />,
      },
      {
        path: "import-hospital",
        exact: true,
        element: <HmoRoute component={HmoUploadHospitals} />,
      },
      {
        path: "subscribers",
        exact: true,
        element: <HmoRoute component={Subcribers} />,
      },

      {
        path: "subscriptions",
        exact: true,
        element: <UserRoute component={Subscription} />,
      },
      {
        path: "hmo/subscriptions",
        exact: true,
        element: <HmoRoute component={HmoSubscriptions} />,
      },
      {
        path: "hmo/hospital-treatments",
        exact: true,
        element: <HmoRoute component={HospitalTreatments} />,
      },
      {
        path: "hmo/one-treatment",
        exact: true,
        element: <HmoRoute component={OneHospitalTreatments} />,
      },
      {
        path: "hospital/hospital-treatments",
        exact: true,
        element: <HospitalRoute component={HospHospitalTreatments} />,
      },
      {
        path: "hospital/one-treatments",
        exact: true,
        element: <HospitalRoute component={OneHospHospitalTreatments} />,
      },
      {
        path: "hospital-invoices",
        exact: true,
        element: <HospitalRoute component={HospitalInvoices} />,
      },
      // HmoInvoices
      {
        path: "hmo-invoices",
        exact: true,
        element: <HmoRoute component={HmoInvoices} />,
      },
      {
        path: "hmo/change-password",
        exact: true,
        element: <HmoRoute component={HmoChangePassword} />,
      },
      {
        path: "benefits",
        exact: true,
        element: <HmoRoute component={HmoPackageBenfits} />,
      },
      {
        path: "payment-confirm",
        exact: true,
        element: <UserRoute component={PaymentConfirm} />,
      },
      {
        path: "my-beneficiaries",
        exact: true,
        element: <UserRoute component={UserManageBeneficiaries} />,
      },
      {
        path: "add-beneficiaries",
        exact: true,
        element: <UserRoute component={AddBeneficiaries} />,
      },
      {
        path: "orders",
        exact: true,
        element: <HmoRoute component={HMOServicePayment} />,
      },
      {
        path: "payment-history",
        exact: true,
        element: <UserRoute component={SubcriberPaymentLog} />,
      },
      {
        path: "/view-details/:id",
        exact: true,
        element: <PrivateRoute component={FullSubcriptionDetails} />,
      },
      {
        path: "/policy",
        exact: true,
        element: <HmoRoute component={Policy} />,
      },
      {
        path: "subscribers/treatment-log",
        exact: true,
        element: <HmoRoute component={TreatmentLog} />,
      },
      {
        path: "benefit",
        exact: true,
        element: <HmoRoute component={BenefitLog} />,
      },
      {
        path: "/customer/treatment-log",
        exact: true,
        element: <UserRoute component={UserTreatmentLog} />,
      },
      {
        path: "/search-hospitals",
        exact: true,
        element: <UserRoute component={SearchHospitals} />,
      },
      {
        path: "/customer/logs",
        exact: true,
        element: <UserRoute component={UserBenefitLog} />,
      },
      {
        path: "/change-password",
        exact: true,
        element: <UserRoute component={ChangePassword} />,
      },
      {
        path: "treatment",
        exact: true,
        element: <HospitalRoute component={TreatmentLogAlone} />,
      },
      {
        path: "log",
        exact: true,
        element: <HospitalRoute component={NewLog} />,
      },
      {
        path: "/confirm-id",
        exact: true,
        element: <HospitalRoute component={ConfirmID} />,
      },
      {
        path: "previous-log",
        exact: true,
        element: <HospitalRoute component={Previous} />,
      },
      {
        path: "view-one",
        exact: true,
        element: <HospitalRoute component={OnePrevious} />,
      },
      {
        path: "/invoice",
        exact: true,
        element: <HospitalRoute component={GenerateInvoice} />,
      },
      {
        path: "/hmos",
        exact: true,
        element: <ITLRoute component={AdminHmoList} />,
      },
      {
        path: "/users",
        exact: true,
        element: <ITLRoute component={AdminUserList} />,
      },

      {
        path: "/AdminInvoice",
        exact: true,
        element: <ITLRoute component={AdminInvoiceList} />,
      },

      {
        path: "/reconcillation",
        exact: true,
        element: <ITLRoute component={AdminReconWithHMO} />,
      },
      {
        path: "invoices-settlement",
        exact: true,
        element: <HmoRoute component={HmoGenerateInvoice} />,
      },
      {
        path: "invoice-list",
        exact: true,
        element: <HmoRoute component={InvoiceList} />,
      },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "*", element: <Navigate to="/404" /> },
      { path: "404", icon: ErrorTwoTone, element: <Error /> },
      { path: "reciept", icon: HomeTwoTone, exact: true, element: <Reciept /> },
      {
        path: "contact-us",
        exact: true,
        element: <UnAuthRoute component={ContactUs} />,
      },
      {
        path: "/admin-receipt",
        exact: true,
        element: <AdminReceipt />,
      },
      {
        path: "sub-receipt",
        exact: true,
        element: <SubReciept />,
      },

      {
        path: "forgot-password",
        exact: true,
        element: <UnAuthRoute component={ForgotPassword} />,
      },
      {
        path: "password-reset",
        exact: true,
        element: <UnAuthRoute component={ChangeForgotPassword} />,
      },
      {
        path: "email/change-password",
        exact: true,
        element: <UnAuthRoute component={Verification} />,
      },
      {
        path: "terms",
        exact: true,
        element: <Term />,
      },
      {
        path: "login",
        icon: LockOpenTwoTone,
        element: <UserUnAuthRoute component={Login} />,
      },
      {
        path: "admin",
        icon: LockOpenTwoTone,
        element: <ITLUnAuthRoute component={AdminLogin} />,
      },
      {
        path: "hmo/login",
        icon: LockOpenTwoTone,
        element: <HmoUnAuthRoute component={HmoLogin} />,
      },
      {
        path: "hmo/forgot-password",
        icon: LockOpenTwoTone,
        element: <HmoUnAuthRoute component={HmoForgotPassword} />,
      },
      {
        path: "hmo/password-reset",
        icon: LockOpenTwoTone,
        element: <HmoUnAuthRoute component={HmoChangeForgotPassword} />,
      },
      // { path: "hmo/password-reset", icon: LockOpenTwoTone, element: <HmoUnAuthRoute component={HmoChangeForgotPassword} /> },
      {
        path: "register",
        icon: LockOpenTwoTone,
        element: <UserUnAuthRoute component={Register} />,
      },
      {
        path: "hmo/register",
        exact: true,
        element: <HmoUnAuthRoute component={HmoRegistration} />,
      },
      { path: "hmo/hospitals", exact: true, element: <HmoHospitals /> },
      { path: "hospitals", exact: true, element: <HmoHospitals /> },
      { path: "hmo/list", exact: true, element: <HmoList /> },
      { path: "hmo/plans", exact: true, element: <ViewPackages /> },
      {
        path: "/hospital/login",
        exact: true,
        element: <HospitalUnAuthRoute component={HospitalLoginAccess} />,
      },
      {
        path: "IdCard",
        exact: true,
        element: <UserRoute component={IdCard} />,
      },
      {
        path: "licence",
        exact: true,
        element: <UserRoute component={Licence} />,
      },
    ],
  },
];

export default ThemeRoutes;
