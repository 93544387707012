/* eslint-disable linebreak-style */
/* eslint-disable no-tabs */
/* eslint-disable import/prefer-default-export */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import Noty from 'noty';
import 'noty/src/noty.scss';
import 'noty/src/themes/metroui.scss';

let noty = null;
export default class Notification {
  constructor() {
    this.options = {
      layout: 'topRight',
      timeout: 6000,
      theme: 'metroui',
      closeWith: ['click', 'button'],
      progressBar: true,
      modal: false,
    };
  }

  _notify(msg, optx) {
    if (msg === null) {
      throw new Error('Please supply message');
    }

    const opts = optx || {};
    this.options = { ...this.options, ...opts };
    this.options.text = msg;
    return new Noty(this.options).show();
  }

  static error(text) {
    if (!noty) noty = new this();
    noty._notify(`<p style="color:white"><i className="fa fa-times-circle"></i> ${text}</p>`, {
      type: 'error',
    });
  }

  static success(text) {
    if (!noty) noty = new this();
    noty._notify(`<p style="color:white"><i className="fa fa-check-circle"></i> ${text}</p>`, {
      type: 'success',
    });
  }

  static warning(text) {
    if (!noty) noty = new this();
    noty._notify(text, {
      type: 'warning',
    });
  }

  static alert(text) {
    if (!noty) noty = new this();
    noty._notify(text, {
      type: 'alert',
    });
  }

  static info(text) {
    if (!noty) noty = new this();
    noty._notify(text, {
      type: 'info',
    });
  }
}
