import * as actions from './hmo.actionTypes'

const reducer = (state = {}, action) => {



    switch (action.type) {

        case actions.HMO_SIGN_UP_REQUEST:
            break;

        case actions.HMO_SIGN_UP_SUCCESS:
            return {
                ...state, currentHmo: action.payload
            }

        case actions.HMO_SIGN_IN_FAILURE:
            return {
                ...state
            }

        case actions.SET_CURRENT_HMO:
            break;



        case actions.HMO_SIGN_IN_REQUEST:

        // case actions.USER_SIGN_IN_FAILURE: 

        case actions.HMO_SIGN_IN_SUCCESS:
            action.payload.hmo.role = "hmo"
            return {
                ...state, currentHmo: action.payload.hmo, token: action.payload.token
            }



        case actions.HMO_LOG_OUT_REQUEST:
            return {}

        case actions.EDIT_HMO_PROFILE_SUCCESS:
            return {
                ...state, currentHmo: action.payload
            }

        case actions.RECOVER_PASSWORD_SUCCESS:
            return {
                ...state
            }


        default:
            return state;
    }
}
export default reducer;