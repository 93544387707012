import * as actions from './session.actionTypes'

const reducer = (state = {}, action) => {



    switch (action.type) {

        case actions.SET_INTENDED_URL:
            return {
                ...state, intended_url: action.payload
            }

        case actions.REMOVE_INTENDED_URL:
            return {}

        default:
            return state;
    }
}
export default reducer;