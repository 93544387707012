import React, { Suspense } from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './assets/style.css'
import { makeStyles } from '@material-ui/core/styles';
//import {History} from './History'
import Spinner from "./views/Spinner/Spinner";
import 'bootstrap/dist/css/bootstrap.css';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

let host = window.location.host;
// let host = "admin.nhim.ng";
// let protocol = window.location.protocol;
let parts = host.split(".");
let subdomain = "";
// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
if (parts.length >= 3) {
	subdomain = parts[0];
	// Remove the subdomain from the parts list
	parts.splice(0, 1);
	if (subdomain === "admin") {
		// Set the location to the new url
		// window.location = "https://" + parts.join(".") + "/" + subdomain;

		window.location = "https://nhim.itl.ng/admin";
	}
}

ReactDOM.render(

	<Suspense fallback={<Spinner />}>
		<BrowserRouter>

			<App />
			{/* <Fab variant='extended' color="secondary"
				aria-label="edit" style={{
					position: 'fixed', bottom: '0',
					right: '0', margin: '20px'
				}} onClick={() => window.history.back()} >
				<ArrowBackIcon /> &nbsp; &nbsp; Previous
			</Fab> */}
		</BrowserRouter>
	</Suspense>
	,
	document.getElementById('root')
);

