export const SET_CURRENT_HMO = "setCurrentHmo";

export const GOOGLE_SIGN_UP_REQUEST = "googleSignUpRequest";
export const GOOGLE_SIGN_UP_FAILURE = "googleSignUpFailure";
export const GOOGLE_SIGN_UP_SUCCESS = "googleSignUpSuccess";

export const HMO_SIGN_UP_REQUEST = "hmoSignUpRequest";
export const HMO_SIGN_UP_SUCCESS = "hmoSignUpSuccess";
export const HMO_SIGN_UP_FAILURE = "hmoSignUpFailure";

export const HMO_SIGN_IN_REQUEST = "hmoSignInRequest";
export const HMO_SIGN_IN_FAILURE = "hmoSignInFailure";
export const HMO_SIGN_IN_SUCCESS = "hmoSignInSuccess";

export const HMO_SIGN_OUT_REQUEST = "hmoSignOutRequest";
export const HMO_SIGN_OUT_FAILURE = "hmoSignOutFailure";
export const HMO_SIGN_OUT_SUCCESS = "hmoSignOutSuccess";

export const EDIT_HMO_PROFILE_REQUEST = "editHmoProfileRequest";
export const EDIT_HMO_PROFILE_SUCCESS = "editHmoProfileSuccess";
export const EDIT_HMO_PROFILE_FAILURE = "editHmoProfileFailure";

export const RECOVER_PASSWORD_REQUEST = "recoverPasswordRequest";
export const RECOVER_PASSWORD_SUCCESS = "recoverPasswordSuccess";
export const RECOVER_PASSWORD_FAILURE = "recoverPasswordFailure";

export const HMO_LOG_OUT_REQUEST = "hmoLogoutRequest";
