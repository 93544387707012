import './session.actionTypes';
import { SET_INTENDED_URL, REMOVE_INTENDED_URL } from './session.actionTypes';


export const setIntendedUrl = (intended_url) => ({
    type: SET_INTENDED_URL,
    payload: intended_url
});

export const removeIntendedUrl = () => ({
    type: REMOVE_INTENDED_URL,
    payload: null
});


export const SetIntendedUrl = (intended_url) => async (dispatch) => {
    dispatch(setIntendedUrl(intended_url));
}