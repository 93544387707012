import axios from 'axios';
import { store } from '../redux/Store';
import Swal from 'sweetalert2';
import { logout } from '../redux/user/user.actions';
import Notification from './notification';

const URL = `${process.env.REACT_APP_API_URL}/api/v1/`;
// const URL = 'http://localhost:5000/api/v1/';

const instance = axios.create({
  baseURL: URL,
});

instance.interceptors.request.use((config) => {

  if (!config.noload) {
    Swal.fire({
      title: 'Please Wait !',
      html: 'Loading',// add html attribute if you want or remove
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    });
  }

  const { token } = store.getState().user;
  config.headers.Authorization = token ? `Bearer ${token}` : null;
  return config;
});

instance.interceptors.response.use(response => {
  Swal.close();
  return response;
}, error => {
  Swal.close();
  console.log(error)
  if (!error.response) {
    Notification.error('Network Error');
  }
  else if (error.response.status === 401) {
    store.dispatch(logout());
    window.location.pathname = "/";
    Notification.error('Token has expired. Sign in to continue.');
  }
  else if (error.response.status === 400) {
    Notification.error('Bad Request');
  }
  else if (error.response.status === 403) {
    Notification.error("Forbiden");
  }
  else if (error.response.status === 404) {
    Notification.error("Not found");
  }

  else if (error.response.status === 500) {
    Notification.error("Internal server error");
  }
  return Promise.reject(error);
});

export default instance;