/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

export const selectHmo = (state) => state.user;

// export const selectIsHmoLoading = createSelector(
//   [selectHmo],
//   (hmo) => hmo.isFetching
// );
export const currentUser = createSelector(
  [selectHmo],
  (hmo) => hmo.currentUser
);

// export const selectCurrentHmoToken = createSelector(
//   [selectHmo],
//   (hmo) => hmo.token
// );


// export const selectHmoError = createSelector(
//   [selectHmo],
//   (hmo) => hmo.errorMessage
// );

// export const selectRecovered = createSelector(
//   [selectHmo],
//   (hmo) => hmo.recovered
// );
