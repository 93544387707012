import React, { useEffect, useState } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from "react-redux";
import { SetIntendedUrl } from '../redux/session/session.actions'
import { connect } from 'react-redux';

export const PrivateRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const token = useSelector(state => state.user.token);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const location = useLocation()
    useEffect(() => {
        if (token) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [token])

    if (isAuthenticated === null) {
        return <></>
    }

    if (!isAuthenticated) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return (
            <Navigate to='/login' />
        )
    }
    else {
        return (
            <Component {...rest} />
        )
    }
});

export const UnAuthRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const token = useSelector(state => state.user.token);
    const intendedUrl = useSelector(state => state.session.intended_url);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    useEffect(() => {
        if (token) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [token])
    if (isAuthenticated === null) {
        return <></>
    }

    if (isAuthenticated) {
        if (intendedUrl) {
            return <Navigate to={intendedUrl} />
        } else {
            return <Navigate to='/' />
        }
    } else {
        return (<Component {...rest} />)
    }
});

export const HmoRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isHmo, setIsHmo] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "hmo") {
                setIsHmo(true)
            } else {
                setIsHmo(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (isAuthenticated === null) {
        return <></>
    }

    if (isHmo === null) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return <Navigate to='/hmo/login' />
    }

    if (!(isAuthenticated && isHmo)) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return (<Navigate to='/hmo/login' />)
    } else {
        return (<Component {...rest} />)
    }
});

export const HospitalRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isHospital, setIsHospital] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "hospital") {
                setIsHospital(true)
            } else {
                setIsHospital(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (isAuthenticated === null) {
        return <></>
    }

    if (isHospital === null) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return <Navigate to='/hospital/login' />
    }

    if (!(isAuthenticated && isHospital)) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return (<Navigate to='/hospital/login' />)
    } else {
        return (<Component {...rest} />)
    }
});

export const UserRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isUser, setIsUser] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "user") {
                setIsUser(true)
            } else {
                setIsUser(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (isAuthenticated === null) {
        return <></>
    }

    if (isUser === null) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return <Navigate to='/login' />
    }

    if (!(isAuthenticated && isUser)) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return (<Navigate to='/login' />)
    } else {
        return (<Component {...rest} />)
    }
});

export const UserUnAuthRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isUser, setIsUser] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "user") {
                setIsUser(true)
            } else {
                setIsUser(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (!isAuthenticated || (isAuthenticated && !isUser)) {
        return (<Component {...rest} />)
    } else {
        return <Navigate to='/dashboard' />
    }
});

export const HmoUnAuthRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isUser, setIsUser] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "hmo") {
                setIsUser(true)
            } else {
                setIsUser(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (!isAuthenticated || (isAuthenticated && !isUser)) {
        return (<Component {...rest} />)
    } else {
        return <Navigate to='/dashboard' />
    }
});

export const HospitalUnAuthRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isUser, setIsUser] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "hospital") {
                setIsUser(true)
            } else {
                setIsUser(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (!isAuthenticated || (isAuthenticated && !isUser)) {
        return (<Component {...rest} />)
    } else {
        return <Navigate to='/dashboard' />
    }
});

export const ITLRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isAdmin, setIsAdmin] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "admin") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (isAuthenticated === null) {
        return <></>
    }

    if (isAdmin === null) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return <Navigate to='/admin' />
    }

    if (!(isAuthenticated && isAdmin)) {
        SetIntendedUrl(`${location.pathname}${location.search}`)
        return (<Navigate to='/admin' />)
    } else {
        return (<Component {...rest} />)
    }
});

export const ITLUnAuthRoute = connect(null, { SetIntendedUrl })(({ SetIntendedUrl, component: Component, ...rest }) => {
    const user = useSelector(state => state.user);
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [isUser, setIsUser] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if (user.token) {
            setIsAuthenticated(true)
            const role = user.currentUser.role;
            if (role === "admin") {
                setIsUser(true)
            } else {
                setIsUser(false)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [user.token])

    if (!isAuthenticated || (isAuthenticated && !isUser)) {
        return (<Component {...rest} />)
    } else {
        return <Navigate to='/dashboard' />
    }
});