import './user.actionTypes';
import api from '../../helpers/api'
import { USER_SIGN_IN_FAILURE, USER_SIGN_IN_SUCCESS, USER_SIGN_UP_FAILURE, USER_SIGN_UP_REQUEST, USER_SIGN_UP_SUCCESS, USER_LOG_OUT_REQUEST, EDIT_USER_PROFILE_SUCCESS } from './user.actionTypes';
import { removeIntendedUrl } from '../session/session.actions';
import getStoredState from 'redux-persist/es/getStoredState';
import Notification from '../../helpers/notification'


/// Sign Up with Email
export const userSignUpRequest = () => ({
    type: USER_SIGN_UP_REQUEST
});

// console.log(getStoredState.user)
export const userSignUpReq = (userCredentials, callback, LoadingCallback) => async (dispatch) => {

    // dispatch(userSignUpReq());
    try {
        const response = await api.post('register', { ...userCredentials });
        if (response.data.status) {
            dispatch(signUpSuccess(response.data.data, response.data.token));
            Notification.success('Account Creation Success!')
        } else {
            dispatch(signUpFailure(response.data.message));
            Notification.error(response.data.message)
        }
        // console.log(userCredentials)
        // console.log('yes');
        // Notification.success('Organization Added Successfully!')

        callback();
        // payload:{user}
    } catch (error) {
        Notification.error(error);
        LoadingCallback();

    }
}

export const updateProfileSuccess = (user) => ({
    type: EDIT_USER_PROFILE_SUCCESS,
    payload: user
});

// user update profile
export const updateProfile = (data) => async (dispatch) => {
    dispatch(updateProfileSuccess(data));
};

export const signUpSuccess = (user, additionalData) => ({
    type: USER_SIGN_UP_SUCCESS,
    payload: { user, additionalData }
});

export const signUpFailure = (error) => ({
    type: USER_SIGN_UP_FAILURE,
    payload: error
});


/// Sign In with Email

export const signInSuccess = (user, token) => ({
    type: USER_SIGN_IN_SUCCESS,
    payload: { user, token }
});

export const signInFailure = (error) => ({
    type: USER_SIGN_IN_FAILURE,
    payload: error
});

export const userSignInRequest = () => ({
    type: USER_SIGN_UP_REQUEST,
});

export const userLogoutRequest = () => ({
    type: USER_LOG_OUT_REQUEST,
});

// user log out
export const logout = (callback) => async (dispatch) => {
    dispatch(userLogoutRequest());
    dispatch(removeIntendedUrl());
    callback();
};

export const userSignInReq = (email, password, callback, LoadingCallback) => async (dispatch) => {
    //  dispatch(USER_SIGN_UP_REQUEST);
    try {
        const response = await api.post('login', { email, password });
        if (response.data.status) {
            dispatch(signInSuccess(response.data.data, response.data.token));
            //  console.log(getStoredState().user())
            Notification.success('Welcome');
            callback();
        } else {
            dispatch(signInFailure('Sorry Unable to Log you In'));
            Notification.error(response.data.message);
            LoadingCallback();
        }
    } catch (error) {
        LoadingCallback();
        dispatch(signInFailure(error));
        Notification.error(error)
    }
};


//Find all Users
export const getAllUsers = (callback) => {
    // api.get(``).then((response)=>{
    //     if(response.data.status){
    //         callback(response.data);
    //     }else{
    //         console.error('something is Wrong')
    //     }
    // }).catch(error=> {console.log(error)})
}

//Find One User
export const getOneUser = (id, callback) => {
    api.get(`/users/${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.data);
                console.error(response);
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

//update or edit user
export const updateUser = (userData, callback) => async (dispatch) => {

    await api.put(`users/${userData.id}`, userData).then((response) => {
        if (response.data.status) {
            Notification.success('User Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}

//Delete User
export const deleteOneUser = (id, callback) => {
    // api.delete(``)
    //   .then((response) => {
    //     if (response.data.status) {
    //       callback(response.data.data);
    //     }
    //     else {
    //       console.error('Something Went Wrong');
    //     }
    //   }).catch(error => { console.error(error) })
};

// change password
export const changePassword = (id, password, callback) => async () => {
    await api.post(`change-password/${id}`, password).then((response) => {
        if (response.data.status) {
            callback(response.data.message);
            Notification.success('Password changed successfully');
        } else {
            Notification.error('Incorrect old password');
        }
    }).catch((error => {
        console.log(error);
        // Notification.error(error);
    }));
}

//// Recover Password
export const recoverPassword = (email, callback) => {
    api.post(`/email/forget-password`, { 'email': email }).then((response) => {

        if (response.data.status) {
            Notification.success(response.data.message)
            callback(response.data)
        }
        else {
            callback(response.data);
            Notification.error(response.data.message)

        }
    }).catch(error => { console.error(error) })
};

//   reset password
export const resetPassword = (token, password, callback) => async () => {
    console.log(token, password)
    await api.post('/password-reset', { 'token': token, 'new_password': password }).then((response) => {
        if (response.data.status) {
            callback(response.data)
            Notification.success(response.data.message)
        }
        else {
            callback(response.data)
            Notification.error(response.data.message);
        }
    }).catch(error => { Notification.error(error) })
}

// List Beneficiaries
export const getBeneficiaries = (id, callback) => async (dispatch) => {
    await api.get(`beneficiaries?user_id=${id}`).then((response) => {
        if (response.data.status) {
            callback(response.data)
        }
        else {
            Notification.error(response.data.message);
        }
    }).catch(error => { Notification.error(error) })
}

// Add Beneficiary
export const addBeneficiaries = (id, data, callback) => async (dispatch) => {
    await api.post(`beneficiaries?user_id=${id}`, data).then((response) => {
        if (response.data.status) {
            callback(response.data)
            Notification.success("Beneficiary added")
        }
        else {
            Notification.error(response.data.message);
        }
    }).catch(error => { Notification.error(error) })
}

//  Del Beneficiary
export const deleteBeneficiary = (id, beneficiary, callback) => async (dispatch) => {

    await api.delete(`beneficiaries/${beneficiary}?user_id=${id}`).then((response) => {
        if (response.data.status) {
            callback(beneficiary, response.data);
        } else {
            Notification.error('Error Occured')

        }
    }).catch(error => Notification.error(error));
}

// Update Beneficiary
export const updateBeneficiary = (user_id, planData, callback) => async (dispatch) => {

    await api.put(`beneficiaries/${planData.id}?user_id=${user_id}`, planData).then((response) => {
        if (response.data.status) {
            Notification.success('Updated Successfully!')
            callback(response.data);
        } else {
            Notification.error('Error Occured')
            // console.log('error')
        }
    }).catch(error => Notification.error(error));
}

export const saveProfilePhoto = (userid, imgData, callback, config) => async (dispatch) => {
    await api.put(`users/upload-picture?user_id=${userid}`, imgData, config).then(response => {
        if (response.data.status) {
            callback(response.data)
            Notification.success('Successfully Sent')
        } else {
            Notification.error('Network Error, try again.')
        }
    }).catch(err => console.log(err))
}

//get user subscriptions
export const getUserSubs = (id, callback) => async (dispatch) => {
    await api.get(`reports/subscription_count?user_id=${id}`).then(response => {
        if (response.data.status) {
            callback(response.data)
            // Notification.success('Successfully Sent')
        } else {
            Notification.error('An error Occured, try again.')
        }
    }).catch(error => Notification.error(error));
}

//get user beneficiaries report
export const getUserBeneficiariesCount = (id, callback) => async (dispatch) => {

    await api.get(`reports/beneficiary_count?user_id=${id}`).then((response) => {
        if (response.data.status) {
            // Notification.success('Hmo Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}


//Find all Users
export const getUsers = (callback, currentPage, hmosPerPage, searchText) => async (dispatch) => {
    let link = currentPage ? `users?page=${currentPage}&size=${hmosPerPage}` :
        `users?page=${currentPage}&size=${hmosPerPage}`;
    link = searchText ? `${link}&search=${searchText}` : link;
    let noload = searchText ? true : false;
    // api.get(link, { noload: noload })
    api.get(link, { noload: noload }).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            console.error('something is Wrong')
        }
    }).catch(error => { console.log(error) })
}

//Find One User
export const getSingleUser = (id, callback) => async (dispatch) => {
    api.get(`/users/${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(response.data);
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

//Find One Subscripton
export const getSingleSub = (id, callback) => async () => {
    api.get(`/hmo-subscriptions/${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(response.data);
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

// /hmo-subscriptions/addBeneficiary?hmosubscription_id=13&subscriber_id=2
//Add Beneficiary to Subscripton
export const addUserToSub = (id, sub_id) => async () => {
    api.get(`/hmo-subscriptions/addBeneficiary?hmosubscription_id=${id}&subscriber_id=${sub_id}`)
        .then((response) => {
            if (response.data.status) {
                Notification.success('Subscriber Added to Subscription')

                // callback(response.data);
            }
            else {
                console.error('Something Went Wrong');
                Notification.error('Error Occured')

            }
        }).catch(error => { console.error(error) })
};

//Find One User
export const sendContactUsMessage = (payload, callback, LoadingCallback) => async (dispatch) => {
    api.post(`/contact-us`, payload)
        .then((response) => {
            if (response.data.status) {
                Notification.success('Message sent successfully')
                callback(response.data);
            }
            else {

                Notification.error(response.data.message);
                LoadingCallback()
            }
        }).catch(error => { console.error(error) })
};
