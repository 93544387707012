import './hmo.actionTypes';
import api from '../../helpers/api'
import {
    USER_SIGN_IN_FAILURE, USER_SIGN_IN_SUCCESS,
    USER_SIGN_IN_REQUEST,
    USER_SIGN_UP_FAILURE, USER_SIGN_UP_REQUEST, USER_SIGN_UP_SUCCESS, USER_LOG_OUT_REQUEST
} from './../user/user.actionTypes';
// import { USER_LOG_OUT_REQUEST } from './../user/user.actionTypes';
import getStoredState from 'redux-persist/es/getStoredState';
import Notification from '../../helpers/notification'
import { currentUser } from '../user/user.selectors';


/// Sign Up with Email
export const hmoSignUpRequest = () => ({
    type: USER_SIGN_UP_REQUEST
});

export const uploadCertificate =
    (id, imgFile, config, callback) => async (dispatch) => {
        await api
            .put(`hmos/upload-licence?hmo_id=${id}`, imgFile, config)
            .then((response) => {
                if (response) {
                    callback(response.data);
                } else {
                    console.log(Notification.error("Error Occured"));
                }
            })
            .catch((err) => console.log(err));
    };

export const hmoSignUpReq = (hmoCredentials, callback, LoadingCallback) => async (dispatch) => {
    // dispatch(hmoSignUpReq());
    try {
        const response = await api.post(`hmos`, { ...hmoCredentials });
        if (response.data.status) {
            dispatch(hmoSignUpSuccess(response.data, response.data.token));
            Notification.success('Account Creation Success!')
            callback(response.data);
        } else {
            dispatch(hmoSignUpFailure(response.data.message));
            Notification.error(response.data.message)
            LoadingCallback();
        }
    } catch (error) {
        Notification.error(error);
        LoadingCallback();
    }
}

export const hmoSignUpSuccess = (user, additionalData) => ({
    type: USER_SIGN_UP_SUCCESS,
    payload: { user, additionalData }
});

export const hmoSignUpFailure = (error) => ({
    type: USER_SIGN_UP_FAILURE,
    payload: error
});


/// Sign In with Email

export const hmoSignInSuccess = (user, token) => ({
    type: USER_SIGN_IN_SUCCESS,
    payload: { user, token }
});

export const hmoSignInFailure = (error) => ({
    type: USER_SIGN_IN_FAILURE,
    payload: error
});

export const hmoSignInRequest = () => ({
    type: USER_SIGN_IN_REQUEST,
});

export const hmoLogoutRequest = () => ({
    type: USER_LOG_OUT_REQUEST,
});

// hmo log out
export const logout = (callback) => async (dispatch) => {
    dispatch(hmoLogoutRequest());
    callback();
};

export const hmoSignInReq = (email, password, callback, LoadingCallback) => async (dispatch) => {
    //  dispatch(USER_SIGN_UP_REQUEST);
    try {
        const response = await api.post(`/hmos/login`, { email, password });
        if (response.data.status) {
            response.data.data.role = "hmo";
            dispatch(hmoSignInSuccess(response.data.data, response.data.token));
            //  console.log(getStoredState().hmo())
            Notification.success('Welcome Back');
            callback();
        } else {
            dispatch(hmoSignInFailure('Sorry Unable to Log you In'));
            Notification.error(response.data.message);
            LoadingCallback();
        }
    } catch (error) {
        LoadingCallback();
        dispatch(hmoSignInFailure(error));
        Notification.error(error)
    }
};


//// Recover Password
export const recoverPassword = (email, callback) => {
    api.post(`/hmos/email/forget-password`, { 'email': email }).then((response) => {
        if (response.data.status) {
            callback(response.data)
        }
        else {
            callback(response.data);
        }
    }).catch(error => { console.error(error) })
};



//   reset password
export const resetPassword = (token, password, callback) => async () => {
    await api.post('/hmos/password-reset', { 'token': token, 'new_password': password }).then((response) => {
        if (response.data.status) {
            callback(response.data)
            Notification.success(response.data.message)
        }
        else {
            callback(response.data)
            //   Notification.error(response.data.message);
        }
    }).catch(error => { Notification.error(error) })
}

//Find all Hmos
export const getHmos = (callback, currentPage, hmosPerPage, searchText) => async (dispatch) => {
    let link = currentPage ? `hmos?page=${currentPage}&size=${hmosPerPage}` :
        `hmos?page=${currentPage}&size=${hmosPerPage}`;
    link = searchText ? `${link}&search=${searchText}` : link;
    let noload = searchText ? true : false;
    // api.get(link, { noload: noload })
    api.get(link, { noload: noload }).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            console.error('something is Wrong')
        }
    }).catch(error => { console.log(error) })
}

//Find One Hmo
export const getOneHmo = (id, callback) => async (dispatch) => {
    api.get(`/hmos/${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(response.data);
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

//Get all Packages
export const getHmoPackages = (id, callback) => async (dispatch) => {
    const link = `/hmo-packages?hmo_id=${id}`;
    try {
        const response = await api.get(link);
        if (response.data.status) {
            callback(response.data);
        }
        else {
            console.error('Something Went Wrong');
        }
    } catch (error) {
        console.error(error)
    }
    // .catch(error => { console.error(error) })
};


//Get all Packages
export const getPackages = (id, callback, code) => async (dispatch) => {
    const link = code ? `/hospitals/hmos?hospital_code=${code}&hmo_id=${id}` : `/hmo-packages?hmo_id=${id}`;
    //   let noload = q ? true : false;

    api.get(link)
        .then((response) => {
            if (response.data.status) {
                callback(response.data);
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

// /hmo-packages/22

//Get One Package
export const getOnePackage = (id, callback) => async (dispatch) => {
    api.get(`/hmo-packages/${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(response.data);
                //    console.log(response.data.status)
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};

// hmo-packages/hospitals?package_code=D&hmo_id=1
//Get Hospital that belongs to a package
export const getPackageHospitals = (id, package_code, callback) => async (dispatch) => {
    api.get(`/hmo-packages/hospitals?hospital_code=${package_code}&hmo_id=${id}`)
        .then((response) => {
            if (response.data.status) {
                callback(response.data);
                //    console.log(response.data.status)
            }
            else {
                console.error('Something Went Wrong');
            }
        }).catch(error => { console.error(error) })
};


//Delete Hmo

export const deleteOneHmo = (id, callback) => async () => {
    // api.delete(``)
    //   .then((response) => {
    //     if (response.data.status) {
    //       callback(response.data.data);
    //     }
    //     else {
    //       console.error('Something Went Wrong');
    //     }
    //   }).catch(error => { console.error(error) })
};

export const listHMOPlans = (id, callback) => async (dispatch) => {
    await api.get(`hmo-packages?hmo_id=${id}`).then(response => {
        if (response.data.status) {
            callback(response.data)

        } else {
            console.log("error")
        }
    }).catch(error => console.log(error))
}

export const addHMOPlan = (plan, callback) => async (dispatch) => {
    await api.post(`hmo-packages`, plan).then(response => {
        if (response.data.status) {
            callback(response.data);
        }
        else {
            console.error('');
        }
    }).catch(err => Notification.error(err))
}

export const updateHMOPlan = (plan, callback) => async (dispatch) => {
    await api.put(`hmo-packages/${plan.id}`, plan).then(response => {
        if (response.data.status) {
            callback(response.data)
        } else {
            console.error("error");
        }
    }).catch(error => { console.log(error) })
}

export const deleteHMOPlan = (id, callback) => async (dispatch) => {
    await api.delete(`hmo-packages/${id}`).then(response => {
        if (response.data.status) {
            callback(response.data)
        } else {
            console.error('error')
        }
    }).catch(error => console.log('error'))
}

//  hospital post end point
export const exportHmoHospital = (hmo_id, excelfile, callback, config, currentPage, listPerPage) => async (dispatch) => {
    await api.post(`hospitals?hmo_id=${hmo_id}&page=${currentPage}&size=${listPerPage}`, excelfile, config).then(response => {
        if (response.data.status) {
            callback(response.data)
            Notification.success(response.data.message)
        } else {
            Notification.error(response.data.message)
        }
    }).catch(err => Notification.error(err))
}

// subscription management
export const getSubscribers = (hmo_id, currentPage, listPerPage, callback) => async (dispatch) => {
    await api.get(`hmo-subscriptions?hmo_id=${hmo_id}&page=${currentPage}&size=${listPerPage}`).then(response => {
        if (response.data.status) {
            callback(response.data)
            // console.log(Notification.success('Listed all subscriber'));
        } else {
            Notification.error('Network Error, Refresh page.')
        }
    }).catch(err => Notification.error(err))
}

// List Treatment log
export const getTreatmentLog = (id, subscriber, callback) => async (dispatch) => {

    await api.get(`treatments?subscription_id=${id}&subscriber_id=${subscriber}`).then((response) => {
        if (response.data.status) {
            // Notification.success('Updated Successfully!')
            callback(response.data);
        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}

// List Treatment/Benefit log Details
export const getBenefitLog = (id, callback) => async (dispatch) => {
    await api.get(`treatments/${id}`).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            console.log(Notification.error('Error Occured'))
        }
    }).catch(error => Notification.error(error));
}


// change password
export const changePassword = (id, password, callback) => async () => {
    await api.post(`hmos/change-password/${id}`, password).then((response) => {
        if (response.data.status) {
            callback(response.data.message);
            Notification.success('Password changed successfully');
        } else {
            Notification.error('Incorrect old password');
        }
    }).catch((error => {
        console.log(error);
        // Notification.error(error);
    }));
}


// Upload beneficiaries image
export const uploadCardImage = (id, imgFile, config, callback) => async (dispatch) => {

    await api.put(`hmos/upload-picture?hmo_id=${id}`, imgFile
        , config).then(
            response => {
                if (response) {
                    callback(response.data)
                }
                else {
                    console.log(Notification.error('Error Occured'))
                }
            }
        ).catch((err) => console.log(err))

}

//update or edit hmo
export const updateHmo = (hmoData, callback) => async (dispatch) => {

    await api.put(`hmos/${hmoData.id}`, hmoData).then((response) => {
        if (response.data.status) {
            Notification.success('Hmo Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}

//get hmo subscriptions count
export const getHmoSubCount = (id, callback) => async (dispatch) => {

    await api.get(`reports/hmosubscription_count?hmo_id=${id}`).then((response) => {
        if (response.data.status) {
            // Notification.success('Hmo Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}


//get hmo hospital report
export const getHmoHospitalCount = (id, callback) => async (dispatch) => {

    await api.get(`reports/hospital_count?hmo_id=${id}`).then((response) => {
        if (response.data.status) {
            // Notification.success('Hmo Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}

//get hmo plans report
export const getHmoPlansCount = (id, callback) => async (dispatch) => {

    await api.get(`reports/plan_count?hmo_id=${id}`).then((response) => {
        if (response.data.status) {
            // Notification.success('Hmo Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}

//Get all Hospital Treatments
export const getHospitalTreatments = (hospitalId, hmoId, callback, currentPage, hmosPerPage, startDate, endDate, searchText) => async (dispatch) => {
    let link = startDate ? `/treatments/all?hospital_id=${hospitalId}&hmo_id=${hmoId}&page=${currentPage}&size=${hmosPerPage}&startdate=${startDate}&enddate=${endDate}` :
        `/treatments/all?hospital_id=${hospitalId}&hmo_id=${hmoId}&page=${currentPage}&size=${hmosPerPage}`;
    link = searchText ? `${link}&search=${searchText}` : link;
    let noload = searchText ? true : false;
    // api.get(link, { noload: noload })
    api.get(link, { noload: noload }).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            console.error('something is Wrong')
        }
    }).catch(error => { console.log(error) })
}

// Get all Invoices
export const getAllInvoices = (id, callback, currentPage, size) => async (dispatch) => {
    let link = currentPage ? `/hmos/getInvoice?hmo_id=${id}&page=${currentPage}&size=${size}` :
        `/hmos/getInvoice?hmo_id=${id}`;
    // link = searchText ? `${link}&search=${searchText}` : link;
    // let noload = searchText ? true : false;
    // api.get(link, { noload: noload })
    // , { noload: noload }
    api.get(link).then((response) => {
        if (response.data.status) {
            callback(response.data);
        } else {
            console.error('something is Wrong')
        }
    }).catch(error => { console.log(error) })
}

//get One Invoice
export const getOneInvoice = (id, callback) => async (dispatch) => {

    await api.get(`/hmos/getInvoice/${id}`).then((response) => {
        if (response.data.status) {
            // Notification.success('Hmo Updated Successfully!')
            callback(response.data);

        } else {
            Notification.error('Error Occured')
        }
    }).catch(error => Notification.error(error));
}

export const addHospitalPayment = (id, payementRef, callback) => async (dispatch) => {
    await api
        .post(`hmos/payInvoice/${id}`, payementRef)
        .then((response) => {
            if (response.status) {
                callback(response.data);
            } else console.log(response.message);
        })
        .catch((err) => console.log(err));
};

export const hmoGenerateInvoice =
    (id, dateGenerated, callback) => async () => {
        await api
            .post(`/hmos/generateSubInvoice?hmo_id=${id}`, dateGenerated)
            .then((response) => {
                if (response.status) {
                    callback(response.data);
                } else console.log(response.message);
            })
            .catch((err) => console.log(err));
    };

export const listInvoicesRaised = (id, callback) => async () => {
    await api
        .get(`/hmos/getSubInvoice?hmo_id=${id}`)
        .then((response) => {
            if (response.status) {
                callback(response.data);
            } else console.log(response.message);
        })
        .catch((err) => console.log(err));
};

export const hmoConfirmInvoice = (id, callback) => async () => {
    await api
        .get(`hmos/confirmSubInvoicePayment/${id}`)
        .then((response) => {
            if (response.status) {
                callback(response.data);
            } else console.log(response.message);
        })
        .catch((err) => console.log(err));
};

export const getOneSubInvoice = (id, callback) => async () => {
    await api
        .get(`/hmos/getSubInvoice/${id}`)
        .then((response) => {
            if (response.status) {
                callback(response.data);
            } else console.log(response.message);
        })
        .catch((err) => console.log(err));
};

//Upload Policy
export const uploadPolicy = (id, file, config, callback) => async (dispatch) => {
    // /hmo-package-benefits/upload?hmo_id
    await api.put(`/hmos/upload-policy-doc?hmo_id=${id}`, file, config).then(
        response => {
            if (response) {
                callback(response.data)
            }
            else {
                console.log(Notification.error('Error Occured'))
            }
        }
    ).catch((err) => console.log(err));

}


//Upload Policy Text Format
export const uploadTextPolicy = (id, data, callback) => async (dispatch) => {
    // /hmo-package-benefits/upload?hmo_id
    await api.put(`/hmos/uploadPolicyDoc?hmo_id=${id}`, { "policy_doc": data }).then(
        response => {
            if (response) {
                Notification.success('Policy Uploaded')
                callback(response.data)
            }
            else {
                console.log(Notification.error('Error Occured'))
            }
        }
    ).catch((err) => console.log(err));
}

//get licence
export const getLicence = (id, callback) => async (dispatch) => {
    await api.get(`/hmos/get-policy-doc?hmo_id=${id}`)
        .then((response) => {
            if (response.status) {
                callback(response.data);
            } else console.log(response.message);
        })
        .catch((err) => console.log(err));
};

//get licence Text
export const getTextLicence = (id, callback) => async (dispatch) => {
    await api.get(`/hmos/getPolicyDoc?hmo_id=${id}`)
        .then((response) => {
            if (response.status) {
                callback(response.data);
                console.log(response.data)
            } else console.log(response.message);
        })
        .catch((err) => console.log(err));
};