import * as actions from './user.actionTypes'

const reducer = (state = {}, action) => {



    switch (action.type) {

        case actions.USER_SIGN_UP_REQUEST:
            break;

        case actions.USER_SIGN_UP_SUCCESS:
            return {
                ...state, currentUser: action.payload
            }

        case actions.USER_SIGN_IN_FAILURE:
            return {}

        case actions.SET_CURRENT_USER:
            break;

        case actions.GOOGLE_SIGN_IN_REQUEST:
            // return{ 
            //     ...state, currentUser }
            break;

        case actions.GOOGLE_SIGN_UP_REQUEST:
            return {
                ...state,
            }

        case actions.USER_SIGN_IN_REQUEST:

        // case actions.USER_SIGN_IN_FAILURE: 

        case actions.USER_SIGN_IN_SUCCESS:
            if (action.payload.user.role === "user") {
                action.payload.user.name = `${action.payload.user.first_name} ${action.payload.user.last_name}`
                return {
                    ...state, currentUser: action.payload.user, token: action.payload.token
                }
            } else if (action.payload.user.role === "hmo") {
                return {
                    ...state, currentUser: action.payload.user, token: action.payload.token
                }
            } 
            else if (action.payload.user.role === "hospital") {
                        return {
                            ...state, currentUser: action.payload.user, token: action.payload.token
                        }
                    }
            else if (action.payload.user.role === "admin") {
                        return {
                            ...state, currentUser: action.payload.user, token: action.payload.token
                        }
                    }


        case actions.USER_LOG_OUT_REQUEST:
            return {}

        case actions.EDIT_USER_PROFILE_SUCCESS:
            return {
                ...state, currentUser: action.payload
            }

        case actions.RECOVER_PASSWORD_SUCCESS:
            return {
                ...state
            }
        
        // case actions.BENEFICIARIES_INFORMATION_SUCCESS:
        //     return{
        //         ...state, 
        //     }


        default:
            return state;
    }
}
export default reducer;