import * as actions from './hospital.action.types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case actions.HOSPITAL_SIGN_IN_FAILURE:
            return {
                ...state
            }
        case actions.SET_CURRENT_HOSPITAL:
            break;
        case actions.HOSPITAL_SIGN_IN_SUCCESS:
            action.payload.hospital.role = "hospital"
            return {
                ...state, currentHospital: action.payload.hospital, token: action.payload.token
            }
        default:
            return state;
    }
}
export default reducer;