import React from 'react';
import { useRoutes } from "react-router-dom";
import Themeroutes from './routes/Router'
import { Provider } from "react-redux";
import { store, persistor } from './redux/Store'
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './assets/jss/Theme-variable';
import GlobalStyles from './assets/jss/Globalstyles';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
} from "@material-ui/core";
// import {toast} from 'react-toastify';
// import 'react-toastify/dist/react-toastify.cjs.development';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



const useStyles = makeStyles({
  floatButton: {
    position:'fixed', bottom:'0', 
			right:'0', margin:'20px',
  },
});

// const store = configureStore();
const App = () => {
  const classes = useStyles();
  const routing = useRoutes(Themeroutes);
// console.log(window.location.pathname);
  return (
    // console.log(store),
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </PersistGate>
      
      { window.location.pathname !='/admin'  ?
      <Box displayPrint="none">
          <Fab variant={window.screen.width> 700 ? 'extended':'round'} color="secondary" 
        aria-label="edit" className={classes.floatButton} onClick={()=>window.history.back()} >
      
				<ArrowBackIcon /> {window.screen.width> 700 ?  'Previous' : ''}
			</Fab>
      </Box>:''}
    </Provider>
  );
}

export default App;
