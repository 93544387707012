import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { currentUser } from "../../redux/hmo/hmo.selectors";
import { Link, useNavigate } from "react-router-dom";
import {
  listInvoicesRaised,
  hmoConfirmInvoice,
} from "../../redux/hmo/hmo.actions";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import LinkIcon from "@material-ui/icons/Link";
import EditIcon from "@material-ui/icons/Edit";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import moment from "moment";
import { currencyFormat } from "../../helpers/hookLib/helperFunctions";
import { SwapCalls } from "@material-ui/icons";

const style = {
  display: "flex",
  flexWrap: "nowrap",
};
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(3, 0, 0, 0),
    padding: theme.spacing(3),
    // background: "#ffebee",
    maxWidth: "100%",
    ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
      position: 'absolute',
      left:0,
      width:'115%',
      // marginLeft:'-30px',
      // marginRight:'-50px',
      padding:'10px',
      overflowX:'hidden',
    }
  },
}));
const InvoiceTable = ({ sno, invoice, confirmInvoice, invoiceList }) => {
  const classes = useStyles();
const navigate = useNavigate();

  const ViewInvoice = () => {
    return(
        <div>
              {/* <Link to='#'> */}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  startIcon={<LinkIcon />}
                  onClick={()=>navigate(`/sub-receipt?invoice_id=${invoice.id}`)}
                >
                  View Invoice
                </Button>
              {/* </Link> */}
            </div>
    )
  }

  return (
    <tr>
      <td>{sno}</td>
      <td>{invoice.summary}</td>
      <td>{currencyFormat(invoice.total_amount)}</td>
      <td>
        {invoice.paid_date === null
          ? "Processing Invoice"
          : moment(invoice.paid_date, "YYYY-MM-DD").format("DD-MM-YYYY")}
      </td>
      <td style={{ width: "150px" }}>
        <div style={style}>
          {
          invoice.itl_payment_confirmation === "yes" &&
          invoice.hmo_payment_confirmation === "yes" &&
          invoice.status == 'paid'
          ? (
            <ViewInvoice />
          ) : 
          // invoice.itl_payment_confirmation === "yes" &&
          //   invoice.hmo_payment_confirmation === "no" && invoice.status != 'paid'
          invoice.status != 'paid' && invoice.status == 'itl paid, awaiting hmo confirmation'
            ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                onClick={() => {
                  confirmInvoice(invoice.id, (response) => {
                    // var idx = invoiceList.findIndex(
                    //   (el) => el.id === invoice.id
                    // );
                    if (response.status) {
                      invoice.map(x => (x.id == response.data.id ? { ...x, 'hmo_payment_confirmation':'yes', 'status':'paid' } : x))
                      // invoiceList[idx] = { ...response.data };
                      // console.log(invoiceList[idx]);
                    }
    //                 setInvoices(
    // invoices.map(x => (x.id == response.data.id ? { ...x, 'status':'paid' } : x)));
    // setHospital(hospitalList.splice(hospitalList.map((e)=>e.id==response.data.id),1,response.data))

                  });
                }}
              >
                Confirm Payment
              </Button>
            </div>
          ) : (
            <ViewInvoice />
          )}
        </div>
      </td>
    </tr>
  );
};

const HmoInvoiceList = ({ listInvoicesRaised, hmoConfirmInvoice, hmo }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const fetchdata = () => {
    listInvoicesRaised(hmo.id, callback);
  };
  useEffect(() => {
    fetchdata();
  }, []);
  const callback = (response) => {
    setInvoices(response.data.data);
  };

  return (
    <>
      <Container maxWidth>
        <Typography variant="h2" align="left">
          Subscription Invoices
        </Typography>
        <Typography variant="span" align="left">
          List of invoices raised for claim to NHIM
        </Typography>
      </Container>
      <Container className={classes.container}>
        <Table responsive="lg" striped bordered>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Period</th>
              <th>Total Amount</th>
              <th>Paid date</th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {invoices.length > 0 ? (
              invoices.map((value, index) => (
                <InvoiceTable
                  sno={index + 1}
                  key={value.id}
                  invoice={value}
                  confirmInvoice={hmoConfirmInvoice}
                  invoiceList={invoices}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  No invoice raised yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

const mapDispatchToProps = () => {
  return { listInvoicesRaised, hmoConfirmInvoice };
};
export default connect(
  () =>
    createStructuredSelector({
      hmo: currentUser,
    }),
  mapDispatchToProps()
)(HmoInvoiceList);
