import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './user/user.reducers';
import hmo from './hmo/hmo.reducers'
import hospital from './hospital/hospital.reducer'
import session from './session/session.reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'session'],
};

const rootReducer = combineReducers({
  user,
  hmo,
  hospital,
  session,
});

export default persistReducer(persistConfig, rootReducer);



// const Rootreducers = () => {

// }

// export default Rootreducers;
